/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { ENS, ENSInterface } from "../ENS";

const _abi = [
  {
    constant: true,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
    ],
    name: "resolver",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
    ],
    name: "owner",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
      {
        name: "label",
        type: "bytes32",
      },
      {
        name: "owner",
        type: "address",
      },
    ],
    name: "setSubnodeOwner",
    outputs: [],
    payable: false,
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
      {
        name: "ttl",
        type: "uint64",
      },
    ],
    name: "setTTL",
    outputs: [],
    payable: false,
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
    ],
    name: "ttl",
    outputs: [
      {
        name: "",
        type: "uint64",
      },
    ],
    payable: false,
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
      {
        name: "resolver",
        type: "address",
      },
    ],
    name: "setResolver",
    outputs: [],
    payable: false,
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "node",
        type: "bytes32",
      },
      {
        name: "owner",
        type: "address",
      },
    ],
    name: "setOwner",
    outputs: [],
    payable: false,
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "node",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "owner",
        type: "address",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "node",
        type: "bytes32",
      },
      {
        indexed: true,
        name: "label",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "owner",
        type: "address",
      },
    ],
    name: "NewOwner",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "node",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "resolver",
        type: "address",
      },
    ],
    name: "NewResolver",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "node",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "ttl",
        type: "uint64",
      },
    ],
    name: "NewTTL",
    type: "event",
  },
] as const;

export class ENS__factory {
  static readonly abi = _abi;
  static createInterface(): ENSInterface {
    return new utils.Interface(_abi) as ENSInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): ENS {
    return new Contract(address, _abi, signerOrProvider) as ENS;
  }
}
